@use '@/styles/utils/mixins.scss' as *;

.brandWrapper {
  display: flex;

  .brandList {
    display: flex;
    flex-direction: column;
    gap: 9.375rem;
    @include tab() {
      gap: 6.75rem;
    }
  }
  .brandItem {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.75rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .brandContent {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;

    .brandDescription {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 1.5rem;
        font-weight: 500;
        &:not(:first-child) {
          margin-top: 2.5rem;
        }
      }
      h1 {
        font-size: 6rem;
      }
      h2 {
        font-size: 4.5rem;
      }
      h3 {
        font-size: 3rem;
      }
      h4 {
        font-size: 2.125rem;
      }
      h5 {
        font-size: 1.5rem;
        @include mob() {
          font-size: 1rem;
        }
      }
      h6 {
        font-size: 1.5rem;
        @include mob() {
          font-size: 1rem;
        }
      }

      p {
        margin-bottom: 1rem;
      }
      ul,
      ol {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
  .brandImageContainer {
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: fit-content;
    max-width: 30rem;
    @include tab() {
      height: 25.625rem;
    }

    @include mob() {
      height: 33.813rem;
      max-width: 19.563rem;
    }
    .brandImage {
      max-width: 100%;
      height: auto;
      object-fit: cover;
      object-position: bottom center;
    }
  }
}
